
















































































import { Component } from 'vue-property-decorator';
import TableComponent from '@/mixins/table-component';
import { EventViewEntityModel, EventViewQueryModel, ParkingEventViewQueryModel } from '@/entity-model/event-view-entity';
import EventViewService from '@/service/event-view';
import EventViewDetailComponent from './event-view-detail.vue';
import { PropertyEntityType } from '@/model/enum';

@Component({
    components: {
        'event-view-detail-component': EventViewDetailComponent
    }
})
export default class EventViewListComponent extends TableComponent<EventViewEntityModel, EventViewQueryModel> {
    PropertyEntityType = PropertyEntityType;
    drawerVisible: boolean = false;
    currentEventId: string = null;
    currentEventTitle: string = null;
    currentEventIndex: number = null;
    batchProcessVisible: boolean = false;
    batchProcessComment: string = '';

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.isFirstLoad = true;
            vm.initPage();
        });
    }
    get PageEventType() {
        return this.$route.params.type;
    }

    initPage() {
        let queryModel = null;
        if (this.PageEventType === 'parking') {
            queryModel = new ParkingEventViewQueryModel();
        } else {
            queryModel = new EventViewQueryModel();
        }
        this.initTable({ listFunc: EventViewService.query, queryModel, tableColumns: EventViewEntityModel.getTableColumns() });
        this.getList();
    }

    detailClick(model: EventViewEntityModel, index: number) {
        this.drawerVisible = true;
        this.currentEventId = model.id;
        this.currentEventTitle = model.eventName;
        this.currentEventIndex = index;
    }
    onDrawerClose() {
        this.drawerVisible = false;
    }
    eventChange(step: number) {
        const nextIndex = this.currentEventIndex + step;
        if (nextIndex < 0) {
            this.showMessageInfo('已经是第一个了');
            return;
        }
        if (nextIndex > this.listData.length - 1) {
            this.showMessageInfo('已经是最后一个了');
            return;
        }
        this.detailClick(this.listData[nextIndex], nextIndex);
    }
    exportClick() {
        this.startFullScreenLoading('正在导出Excel...');
        EventViewService.exportExcel(this.queryModel).then(res => {
            this.showMessageSuccess('成功导出Excel');
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }
    batchProcess() {
        this.batchProcessComment = '';
        this.batchProcessVisible = true;
    }
    batchProcessClose() {
        this.batchProcessComment = '';
        this.batchProcessVisible = false;
        this.getList();
    }
    batchProcessOK() {
        if (!this.batchProcessComment) {
            this.showMessageWarning('请填写处理意见');
            return;
        }
        const params = {
            ids: this.getSelectedRowIds(),
            comments: this.batchProcessComment
        };
        this.startFullScreenLoading();
        EventViewService.batchProcess(params).then(res => {
            this.batchProcessVisible = false;
            this.getList();
            this.batchProcessComment = '';
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }
    eventHandled() {
        this.onDrawerClose();
        this.currentEventId = '';
        this.page = 1;
        this.getList();
    }
}

