var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "event-view-detail-component" },
    [
      _c(
        "a-spin",
        {
          staticStyle: { overflow: "hidden" },
          attrs: { spinning: _vm.loading }
        },
        [
          _vm.eventModel
            ? _c(
                "div",
                [
                  _c(
                    "a-card",
                    {
                      staticClass: "event-view-detail-section",
                      attrs: { title: "事件信息" }
                    },
                    _vm._l(_vm.tableColumns, function(data) {
                      return _c(
                        "a-row",
                        {
                          key: data.dataIndex,
                          staticClass: "event-view-detail-section-row",
                          attrs: { gutter: [16, 16] }
                        },
                        [
                          _c("a-col", { attrs: { span: 4 } }, [
                            _vm._v(_vm._s(data.title))
                          ]),
                          _c("a-col", { attrs: { span: 20 } }, [
                            _vm._v(_vm._s(_vm.eventModel[data.dataIndex]))
                          ])
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _c(
                    "a-card",
                    {
                      staticClass: "event-view-detail-section",
                      attrs: { title: "实时监控" }
                    },
                    _vm._l(_vm.eventModel.cameraList, function(
                      cameraData,
                      cameraIndex
                    ) {
                      return _c(
                        "a-row",
                        {
                          key: cameraIndex,
                          staticClass: "event-view-detail-section-row",
                          attrs: { gutter: [16, 16] }
                        },
                        [
                          _c("a-col", { attrs: { span: 21 } }, [
                            _vm._v(_vm._s(cameraData.name))
                          ]),
                          _c(
                            "a-col",
                            { attrs: { span: 3 } },
                            [_c("a-icon", { attrs: { type: "video-camera" } })],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _c(
                    "a-card",
                    {
                      staticClass: "event-view-detail-section",
                      attrs: { title: "抓拍图片" }
                    },
                    _vm._l(_vm.eventModel.imageList, function(
                      imageData,
                      imageIndex
                    ) {
                      return _c(
                        "a-row",
                        {
                          key: imageIndex,
                          staticClass: "event-view-detail-section-row ",
                          attrs: { gutter: [16, 16] }
                        },
                        [
                          _c(
                            "a-col",
                            {
                              staticStyle: { "line-height": "25px" },
                              attrs: { span: 21 }
                            },
                            [_vm._v(_vm._s(imageData.name))]
                          ),
                          _c(
                            "a-col",
                            {
                              staticClass: "img-row",
                              attrs: { span: 3 },
                              on: {
                                click: function($event) {
                                  return _vm.previewImage(imageData)
                                }
                              }
                            },
                            [
                              _c("a-icon", {
                                staticStyle: { color: "#1890ff" },
                                attrs: { type: "picture" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _c(
                    "a-card",
                    {
                      staticClass: "event-view-detail-section",
                      attrs: {
                        title:
                          _vm.eventModel.status === "WCL"
                            ? "处理意见"
                            : "处理记录"
                      }
                    },
                    [
                      _vm.eventModel.status === "WCL"
                        ? _c(
                            "div",
                            [
                              _c("a-textarea", {
                                staticStyle: { height: "150px" },
                                attrs: {
                                  placeholder: "请输入处理意见",
                                  disabled: _vm.commentsDisabled
                                },
                                model: {
                                  value: _vm.eventModel.comments,
                                  callback: function($$v) {
                                    _vm.$set(_vm.eventModel, "comments", $$v)
                                  },
                                  expression: "eventModel.comments"
                                }
                              }),
                              _c("br"),
                              _c("br"),
                              _c(
                                "div",
                                { staticClass: "text-right" },
                                [
                                  _c(
                                    "jtl-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        "click-prop": _vm.handleEventClick.bind(
                                          this
                                        ),
                                        disabled: _vm.commentsDisabled
                                      }
                                    },
                                    [_vm._v("确认")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              _c(
                                "a-row",
                                { attrs: { gutter: [16, 16] } },
                                [
                                  _c("a-col", { attrs: { span: 4 } }, [
                                    _vm._v("处理人员")
                                  ]),
                                  _c("a-col", { attrs: { span: 20 } }, [
                                    _vm._v(_vm._s(_vm.eventModel.processMan))
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "a-row",
                                { attrs: { gutter: [16, 16] } },
                                [
                                  _c("a-col", { attrs: { span: 4 } }, [
                                    _vm._v("处理时间")
                                  ]),
                                  _c("a-col", { attrs: { span: 20 } }, [
                                    _vm._v(_vm._s(_vm.eventModel.handleTs))
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "a-row",
                                { attrs: { gutter: [16, 16] } },
                                [
                                  _c("a-col", { attrs: { span: 4 } }, [
                                    _vm._v("处理意见")
                                  ]),
                                  _c("a-col", { attrs: { span: 20 } }, [
                                    _vm._v(_vm._s(_vm.eventModel.comments))
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c(
        "a-drawer",
        {
          attrs: {
            placement: _vm.placement,
            width: _vm.width,
            closable: _vm.closable,
            visible: _vm.drawerVisible,
            "destroy-on-close": true
          },
          on: { close: _vm.drawerClose }
        },
        [
          _c(
            "div",
            {
              style: {
                backgroundColor: "#05BC9D",
                width: "100px",
                padding: "10px 0 10px 15px",
                borderRadius: "0px 50px 50px 0px",
                color: "white"
              },
              attrs: { slot: "title" },
              slot: "title"
            },
            [_vm._v(" 工单详情 ")]
          ),
          _c("work-order-detail-component", {
            attrs: {
              "if-work-order": true,
              "service-type": _vm.serviceType,
              "process-instance-id": _vm.processInstanceId,
              "view-type": _vm.viewType
            },
            on: { showDrawer: _vm.showDrawer }
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { visible: _vm.imageVisible },
          on: { ok: _vm.handleOk, cancel: _vm.handleCancel }
        },
        [
          _c("img", {
            staticStyle: {
              height: "400px",
              width: "100%",
              "object-fit": "contain"
            },
            attrs: { src: _vm.selectedImg }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }