import { BaseEntityModel } from '@/model/base-model';
import { QueryPageModel } from '@/model/query-model';
import { QueryControl, QueryControlType } from '@/model/query-control';
import moment from 'moment';

export class EventViewEntityModel extends BaseEntityModel {
    comments: string = undefined;
    code: string = undefined;
    deviceType: string = undefined;
    entityId: string = undefined;
    eventId:string = undefined;
    eventName: string = undefined;
    level: string = undefined;
    levelDesc: string = undefined;
    status:string = undefined;
    statusDesc:string = undefined;
    spaceName:string = undefined;
    remark: string = undefined;
    entityCode: string = undefined;
    entityName:string = undefined;
    outputData:Object = undefined;
    processMan:string = undefined;
    lastUpdateTs:string = undefined;
    handleTs:string = undefined;
    facilityCode:string = undefined;
    facilityName:string = undefined;
    cameraList: Array<any> = [];
    imageList: Array<any> = [];
    // picUrls: string = undefined;
    // videoUrls: string = undefined;
    // moduleName: string = undefined;
    // title: string = undefined;

    static getTableColumns() {
        return [
            {
                title: '事件名称',
                dataIndex: 'eventName',
                width: 250
            },
            {
                title: '类型',
                dataIndex: 'levelDesc'
            },
            {
                title: '描述',
                dataIndex: 'remark',
                // scopedSlots: { customRender: 'outputData' },
                width: 400,
                ellipsis: true
            },
            // {
            //     title: '设备',
            //     dataIndex: 'entityName',
            //     width: 300
            // },
            // {
            //     title: '产品名称',
            //     dataIndex: 'deviceType',
            //     width: 200
            // },
            {
                title: '发生位置',
                dataIndex: 'spaceName',
                width: 150
            },

            {
                title: '发生时间',
                dataIndex: 'createdTime',
                scopedSlots: { customRender: 'createdDT' }
            },
            {
                title: '处理状态',
                dataIndex: 'statusDesc',
                width: 200
            },

            // {
            //     title: '编码',
            //     dataIndex: 'code',
            //     ellipsis: true,
            //     width: 80
            // },
            // {
            //     title: '所属模块',
            //     dataIndex: 'moduleName',
            //     ellipsis: true
            // },
            // {
            //     title: '级别',
            //     dataIndex: 'levelDesc',
            //     width: 100
            // },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    static getDetailColumns() {
        return [
            {
                title: '事件名称',
                dataIndex: 'eventName'
            },
            {
                title: '事件类型',
                dataIndex: 'levelDesc'
            },
            {
                title: '事件描述',
                dataIndex: 'remark'
            },
            {
                title: '发生位置',
                dataIndex: 'spaceName'
            },
            {
                title: '设备编号',
                dataIndex: 'facilityCode'
            },
            {
                title: '设备名称',
                dataIndex: 'facilityName'
            },
            {
                title: '发生时间',
                dataIndex: 'createdTime'
            },
            {
                title: '事件状态',
                dataIndex: 'statusDesc'
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        this.facilityName = _.get(json, 'facilityName') || _.get(json, 'deviceName');
        const ts = _.get(json, 'handleTs');
        if (ts) {
            this.handleTs = moment(ts).format('YYYY-MM-DD HH:mm:ss');
        } else {
            this.handleTs = '';
        }
        return this;
    }
}

export class EventViewQueryModel extends QueryPageModel {
    @QueryControl({
        label: '事件类型',
        type: QueryControlType.SELECT,
        options: [
            {
                value: 0,
                name: '信息'
            },
            {
                value: 1,
                name: '告警'
            },
            {
                value: 2,
                name: '故障'
            }
        ],
        hasAllOption: true,
        index: 11
    })
    level: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        label: '处理状态',
        type: QueryControlType.SELECT,
        options: [
            {
                value: 'YCL',
                name: '已处理'
            },
            {
                value: 'WCL',
                name: '未处理'
            }
        ],
        hasAllOption: true,
        index: 12
    })
    status: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        placeholder: '请输入事件名称',
        type: QueryControlType.TEXT,
        index: 13
    })
    name: string = undefined;

    toService() {
        const data :any = {};
        data.params = {
            name: this.name,
            level: this.level || undefined,
            status: this.status || undefined
        };
        return data;
    }
}

export class ParkingEventViewQueryModel extends EventViewQueryModel {
    @QueryControl({
        label: '设备类型',
        type: QueryControlType.SELECT,
        hasAllOption: true,
        index: 1
    })
    facilityType: string = JTL.CONSTANT.ALL_OPTION.value;

    toService() {
        const data :any = {};
        data.params = {
            name: this.name,
            level: this.level || undefined,
            status: this.status || undefined
            // 停车场查询接口特有的参数
        };
        return data;
    }
}
